<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BModal, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'MessageDeny',
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    credentialProcessRequestId: {
      type: Number,
      default: undefined,
    },
    credentialProcessRequestMessage: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      targetItem: {
        description: '',
        id: '',
      },
    }
  },
  computed: {

  },
  methods: {
    ...mapActions({
      updateMessageProcess: 'processRequest/updateMessageProcess',
    }),
    async onSave(type = null) {
      try {
        const response = await this.updateMessageProcess({
          id: this.credentialProcessRequestId,
          message: this.targetItem.description,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('messageDeny')
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },

    onShow() {
      if (this.credentialProcessRequestId) {
        this.targetItem.id = this.credentialProcessRequestId
      }
      if (this.credentialProcessRequestMessage){
        this.targetItem.description = this.credentialProcessRequestMessage
      }
    },
    onHide() {
      this.targetItem = {
        description: '',
        id: '',
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="messageDeny"
        body-class="position-static"
        centered
        :title="'Thêm lời nhắn'"
        no-close-on-backdrop
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-form-group label-for="description">
          <template v-slot:label>
            Lý do từ chối <span class="text-danger">*</span>
            <validation-provider
                #default="{ errors }"
                name="Lý do từ chối"
                rules="required"
            >
              <b-form-textarea
                  id="description"
                  v-model="targetItem.description"
                  placeholder="Nhập lý do từ chối"
                  :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </template>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" />Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('messageDeny')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" />Bỏ qua
            </span>

          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<style scoped lang="scss">

</style>
