<template>
  <div class="row">
    <b-container fluid>
      <b-overlay>

        <b-row>
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="4"
                  class="text-left"
                >
                  <b-form-group
                    label="Trạng thái"
                    label-for="filter.status"
                  >
                    <v-select
                      v-model="filter.status"
                      :options="statusesDropdownOptions"
                      :reduce="option => option.value"
                      @input="onStatusChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                  class="text-left"
                >
                  <b-form-group
                    label="Loại văn bằng, chứng chỉ"
                    label-for="credentialId"
                  >
                    <v-select
                      v-model="filter.credentialId"
                      :options="credentialDropdownOptions"
                      :reduce="option => option.value"
                      @input="onCredentialChange"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="4"
                  class="text-left"
                >
                  <b-form-group
                    label="Loại yêu cầu"
                    label-for="certificateProcessTypeId"
                  >
                    <v-select
                      v-model="filter.certificateProcessTypeId"
                      :options="certificateProcessTypeDropdownOptions"
                      :reduce="option => option.value"
                      @input="onChangeCertificateProcessType"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  class="text-right"
                >
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="my-2 mx-1"
                      variant="primary"
                      @click="onSearch"
                    >
                      <feather-icon icon="SearchIcon" />
                      Tìm kiếm
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-card>
              <b-card-header>
                <b-card-title>
                  Danh sách yêu cầu
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  style-class="vgt-table striped bordered"
                  :columns="columns"
                  :rows="credentialProcessRequestDisplay"
                  :line-numbers="true"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'createdTime'">
                      {{ convertDatetimeToDate(props.row.createdTime) }}
                    </span>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    <span
                      v-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-b-modal.processRequestModal
                          class="btn-icon"
                          variant="primary"
                          size="sm"
                          @click="onProcessCredentialProcessRequest(props.row)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                  </template>
                </vue-good-table>
              </b-card-body>
            </b-card>
            <ProcessRequestModal
              ref="processRequestModal"
              :item="currentCredentialProcessRequest"
              @succeed="onSucceed"
            />
          </b-col>
        </b-row>
      </b-overlay>
    </b-container>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard, BCardBody, BCardHeader, BCardTitle,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormGroup, BFormInput,
  BFormSelect, BModal, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import ProcessRequestModal from '@/views/credential-process-request/ProcessRequest.vue'

export default {
  name: 'CredentialProcessRequest',
  directives: {
    Ripple,
  },
  components: {
    ProcessRequestModal,
    BFormInput,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BRow,
    BModal,
    Ripple,
    vSelect,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      user: getUser(),
      dataTest: [
        {
          name: 'test',
          credentialProcessType: 'test',
          credentialName: 'test',
          status: 0,
        },
        {
          name: 'test2',
          credentialProcessType: 'test2',
          credentialName: 'test2',
          status: 1,
        },
      ],
      filter: {
        currentPage: 1,
        itemsPerPage: 10000,
        status: 0,
        credentialId: null,
        certificateProcessTypeId: null,
      },
      filterGetAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      currentCredentialProcessRequest: undefined,
      credentialProcessRequestDisplay: [],
      columns: [
        {
          label: 'Tên',
          field: 'studentName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
        },
        {
          label: 'Loại yêu cầu',
          field: 'certificateProcessTypeName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên văn bằng, chứng chỉ',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thời gian tạo yêu cầu',
          field: 'createdTime',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'credentialProcessRequest/statuses',
      credentialProcessRequest: 'credentialProcessRequest/credentialProcessRequest',
      credentialsDropdown: 'dropdown/credentials',
      certificateProcessTypes: 'dropdown/certificateProcessTypes',
    }),
    statusesDropdownOptions() {
      return [{ label: 'Tất cả ', value: null }, ...this.statuses]
    },
    credentialDropdownOptions() {
      return [{ label: 'Tất cả ', value: null }, ...this.credentialsDropdown]
    },
    certificateProcessTypeDropdownOptions() {
      return [{ label: 'Tất cả ', value: null }, ...this.certificateProcessTypes]
    },
  },
  async created() {
    await Promise.all([
      this.readCredentialProcessRequests(this.filter),
      this.getAllCredentials(this.filterGetAll),
      this.getCertificateProcessType(this.filterGetAll),
    ])
    this.credentialProcessRequestDisplay = this.credentialProcessRequest.map(request => ({
      ...request,
      filesId: request.filesId ? request.filesId.split(',').map(Number) : [],
      filesName: request.filesName ? request.filesName.split(',').map(String) : [],

    }))
  },
  methods: {
    ...mapActions({
      readCredentialProcessRequests: 'credentialProcessRequest/readCredentialProcessRequests',
      getAllCredentials: 'dropdown/getAllCredentials',
      getCertificateProcessType: 'dropdown/getCertificateProcessType',
    }),

    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    onProcessCredentialProcessRequest(row) {
      this.currentCredentialProcessRequest = row
    },
    onDeleteCredentialProcessRequest(row) {
      this.currentCredentialProcessRequest = row
    },
    async onSucceed() {
      await this.readCredentialProcessRequests(this.filter)
      this.credentialProcessRequestDisplay = this.credentialProcessRequest.map(request => ({
        ...request,
        filesId: request.filesId ? request.filesId.split(',').map(Number) : [],
        filesName: request.filesName ? request.filesName.split(',').map(String) : [],
      }))
    },
    async onStatusChange(event) {
      this.filter.status = event
    },
    async onCredentialChange(event) {
      this.filter.credentialId = event
    },
    async onChangeCertificateProcessType(event) {
      this.filter.certificateProcessTypeId = event
    },
    async onSearch() {
      await this.readCredentialProcessRequests(this.filter)
      this.credentialProcessRequestDisplay = this.credentialProcessRequest.map(request => ({
        ...request,
        filesId: request.filesId ? request.filesId.split(',').map(Number) : [],
        filesName: request.filesName ? request.filesName.split(',').map(String) : [],
      }))
    },
    convertDatetimeToDate(datetimeStr) {
      const datePart = datetimeStr.split(' ')[0]

      const [year, month, day] = datePart.split('-')

      return `${day}/${month}/${year}`
    },
  },
}
</script>
<style scoped lang="scss">

</style>
